<template>
  <v-app>
    <transition name="slide-fade" v-for="(post, i) in posts" :key="i">
      <Post class="mb-11" :post="post" :color="postColor"
        @postLikeToggle="postLikeToggled"
      />
    </transition>
  </v-app>
</template>
<script>
export default {
  name: 'Home',
  components: {
    Post: () => import('@/components/Post'),
  },
  data: () => ({
    user: null,
    posts: [],
    postColor: '',
    storeWatchers: {
      posts: null,
      user: null,
      color: null,
    } 
  }),
  created () {
    this.$store.dispatch('post/getPosts').then(() => {
      this.posts = this.$store.getters['post/posts']
      this.watchAndSetPosts()
    })
    
    this.user = this.$store.getters['user/user'] // There, this.user assignment isnt inside an dispatch promise result due firebase onAuthStateChanged already doing that dispatch
    this.watchAndSetUser()

    this.postColor = this.$store.getters['app/app'].color.name
    this.watchAndSetColor()
  },
  beforeDestroy () {
    this.storeWatchers.posts()
    this.storeWatchers.user()
    this.storeWatchers.color()
  },
  methods: {
    watchAndSetPosts () {
      this.$store.dispatch('post/watchPosts') 
      this.storeWatchers.posts = this.$store.watch(
        state => state.post.posts,
        posts => { this.posts = posts },
        { deep: true } 
      )
    },
    watchAndSetUser () {
      this.storeWatchers.user = this.$store.watch(
        state => state.user.user,
        async changedUser => { 
          this.user = changedUser

          const likedPosts = changedUser?.likedPosts || []
          this.setLikeOnPosts(likedPosts)
        },
        { deep: true } 
      )
    },
    watchAndSetColor () {
      this.storeWatchers.color = this.$store.watch(
        state => state.app.color,
        color => { this.postColor = color.name },
        { deep: true } 
      )
    },
    setLikeOnPosts (likedPosts) {
      if (likedPosts.length === 0) {
        this.posts = this.posts.map(post => { 
          post.liked = false 
          return post
        })
        return
      }

      likedPosts.forEach(postID => {
        const foundIndex = this.posts.findIndex(findingPost => findingPost.id === postID)

        if (foundIndex > -1) 
          this.posts[foundIndex].liked = true
      })
    },
    postLikeToggled (post) {
      this.updateUserLikedPosts(post)   
    },
    async updateUserLikedPosts (post) {
      let likedPosts = this.$store.getters['user/user']?.likedPosts || []

      if (post.liked) 
        likedPosts.push(post.id)
      else 
        likedPosts = likedPosts.filter(filteringPost => filteringPost !== post.id)

      await this.$store.dispatch('user/updateUser', { likedPosts })
    },
  }
}
</script>
<style lang="scss">
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-110px);
  opacity: 0;
}
</style>